<!--综合测评选项-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">综合测评选项</div>
    <!--=====================================工具栏========================================-->
    <div class="page-tool">
      <div style="width: 80%">
        <el-row :gutter="20">
          <el-col
            :span="6"
            style="display: flex; align-items: center"
          >
            <span class="fcolor42 mr5 rspan">类别:</span>
            <el-select
              v-model="lb"
              size="small"
              clearable
              style="width: 200px"
            >
              <el-option
                label="德育素质"
                :value="1"
              ></el-option>
              <el-option
                label="智育素质"
                :value="2"
              ></el-option>
              <el-option
                label="体育素质"
                :value="3"
              ></el-option>
              <el-option
                label="美育素质"
                :value="4"
              ></el-option>
              <el-option
                label="劳动素质"
                :value="5"
              ></el-option>
              <el-option
                label="能力素质"
                :value="6"
              ></el-option>
            </el-select>
          </el-col>
          <el-col
            :span="8"
            style="display: flex; align-items: center"
          >
            <span class="fcolor42 mr5 rspan">评分内容:</span>
            <el-input
              style="width: 200px"
              v-model="keyword"
              placeholder="请输入评分内容"
              size="small"
            ></el-input>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="small"
              @click="getDataPageList"
            >搜索</el-button>
          </el-col>
        </el-row>
      </div>
      <el-button-group>
        <el-button
          size="small"
          icon="el-icon-refresh"
          @click="reload()"
        ></el-button>
      </el-button-group>
    </div>
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 270"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column
          prop="lb"
          label="类别"
          width="100"
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.lb === 1">德育素质</el-tag>
            <el-tag v-if="scope.row.lb === 2">智育素质</el-tag>
            <el-tag v-if="scope.row.lb === 3">体育素质</el-tag>
            <el-tag v-if="scope.row.lb === 4">美育素质</el-tag>
            <el-tag v-if="scope.row.lb === 5">劳动素质</el-tag>
            <el-tag v-if="scope.row.lb === 6">能力素质</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="lx"
          label="类型"
          width="100"
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.lx === 1">加分要素</el-tag>
            <el-tag
              type="danger"
              v-if="scope.row.lx === 2"
            >减分要素</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="pfnr"
          label="评分内容及标准"
        > </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="120"
        >
          <template slot-scope="scope">
            <el-button
              @click="Applay(scope.row)"
              type="text"
              size="small"
            >
              申请
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="综合素质测评项目"
      :visible.sync="dialogFormVisible"
      width="50%"
      :close-on-click-modal="false"
      @close="closeDialog"
      top="5vh"
    >
      <el-scrollbar
        style="height: 550px"
        class="myscrollbar"
      >
        <el-form
          :model="forminfo"
          v-if="dialogFormVisible"
          ref="formRef"
          :rules="forminfoRules"
        >
          <el-form-item
            label="评分内容及标准"
            label-width="150px"
          >
            <el-input
              type="textarea"
              :autosize="{ minRows: 5, maxRows: 5 }"
              readonly=""
              v-model="PFNR"
            >
            </el-input>
          </el-form-item>
          <el-row>
            <el-col :span="24">
              <el-form-item
                label="选项"
                label-width="150px"
                prop="XID"
              >
                <el-select
                  v-model="forminfo.XID"
                  style="width:100%"
                  @change="xmchange"
                >
                  <el-option
                    v-for="(item, index) in childList"
                    :key="index"
                    :label="
                      item.sm
                    "
                    :value="
                      item.id
                    "
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item
                label="分数"
                label-width="150px"
                prop="FS"
              >
                <el-input
                  v-model="forminfo.FS"
                  readonly
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="学期"
                label-width="150px"
                prop="XQ"
              >
                <el-select v-model="forminfo.XQ">
                  <el-option
                    v-for="(item, index) in xqlist"
                    :key="index"
                    :label="item.ksnf + '-' + item.jsnf + '第' + item.xq + '学期'"
                    :value="item.ksnf + '-' + item.jsnf + '第' + item.xq + '学期'"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item
            label="加分说明"
            label-width="150px"
            prop="JFSM"
          >
            <el-input
              type="textarea"
              :autosize="{ minRows: 5, maxRows: 5 }"
              placeholder="请输入内容"
              v-model="forminfo.JFSM"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="附件材料"
            label-width="120px"
            class="mb10"
          >
            <el-upload
              class="upload-demo"
              :headers="headers"
              :action="uploadurl"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :on-success="handleSuccess"
              :before-remove="beforeRemove"
              :limit="3"
              :on-exceed="handleExceed"
              :file-list="fileList"
            >
              <el-button
                size="small"
                type="primary"
              >点击上传</el-button>
              <div
                slot="tip"
                class="el-upload__tip"
              >
                存在文件必须填写说明，附件类型包括：jpg
              </div>
            </el-upload>
          </el-form-item>
        </el-form>
      </el-scrollbar>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="saveForm"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { GetZHCPXMPageList, saveZHCPSQ, getChildList } from '../../api/zhcp'
import { getAuthorizeButtonColumnList, getXQList } from '../../api/system'
import moment from 'moment'
import { baseUrl } from '../../utils/http'
import store from '../../store/index'
export default {
  data() {
    return {
      downurl: baseUrl,
      headers: null,
      uploadurl: baseUrl + 'File/upload',
      fileList: [],
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      windowHeight: document.documentElement.clientHeight - 50, // 实时屏幕高度
      keyword: '',
      keyValue: '',
      tableloading: true,
      buttonauth: {},
      dataPageList: [],
      formdata: {},
      operation: 1, // 当前操作1=添加，0=编辑
      lx: 1,
      lb: '',
      forminfo: {
        ID: '',
        PID: '',
        XID: '',
        XQ: '',
        JFSM: '',
        FS: 0,
        FJIDS: [],
      },
      xqlist: [],
      PFNR: '',
      spzt: null,
      dialogFormVisible: false, // 是否显示弹出框
      // 表单验证规则对象
      forminfoRules: {
        JFSM: [
          {
            required: true,
            message: '请填写内容',
            trigger: 'blur',
          },
        ],
        FS: [{ required: true, message: '请填写分数', trigger: 'blur' }],
        XQ: [{ required: true, message: '请选择学期', trigger: 'blur' }],
      },
      childList: [],
    }
  },
  created() {
    this.headers = {
      Authorization: 'Bearer ' + store.state.token.access_token,
    }
    this.getDataPageList()
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {
      this.active = 0
      this.$nextTick(() => {
        this.forminfo = JSON.parse(JSON.stringify(this.formdata))
      })
      this.$refs.formRef.resetFields()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    reload() {
      this.$router.go(0)
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
      } else {
        this.keyValue = currentRow.id
      }
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      GetZHCPXMPageList({
        queryJson: JSON.stringify({
          lb: this.lb,
          lx: this.lx,
          keyword: this.keyword,
        }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 时间格式化
    dateFormat: function (row, column) {
      var date = row[column.property]

      if (!date) {
        return ''
      }

      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    dateFormat2(value) {
      if (!value) {
        return ''
      }
      return moment(value).format('YYYY年MM月DD日 HH:mm')
    },
    // 时间格式化
    dateFormat3(value) {
      if (!value) {
        return ''
      }

      return moment(value).format('YYYY-MM-DD')
    },
    showAdd() {
      this.dialogFormVisible = true
    },
    detailInfo() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
      }
    },
    deleteinfo() {},
    saveForm() {
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) {
          return false
        } else {
          this.forminfo.FJIDS = []
          for (var i = 0; i < this.fileList.length; i++) {
            if (this.fileList[i].response) {
              this.forminfo.FJIDS.push(this.fileList[i].response.data.id)
            } else {
              this.forminfo.FJIDS.push(this.fileList[i].id)
            }
          }
          saveZHCPSQ(this.forminfo).then((res) => {
            if (res.code === 200) {
              this.fileList = []
              this.$message.success(res.message)
              this.dialogFormVisible = false
              this.dialogDetailVisible = false
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    Applay(row) {
      this.forminfo.PID = row.id
      this.PFNR = row.pfnr
      getXQList().then((res) => {
        if (res.code === 200) {
          this.xqlist = res.data
        }
      })
      getChildList({ id: row.id }).then((res) => {
        if (res.code === 200) {
          this.childList = res.data
        }
      })
      this.dialogFormVisible = true
    },
    handleRemove(file, fileList) {
      var index = -1
      for (var i = 0; i < this.fileList.length; i++) {
        if (this.fileList[i].name === file.name) {
          index = i
          break
        }
      }
      if (index >= 0) {
        this.fileList.splice(index, 1)
      }
    },
    handlePreview(file) {
      var id = ''
      if (file.response) {
        id = file.response.data.id
      } else {
        id = file.id
      }
      window.open(baseUrl + 'File/download?id=' + id)
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      )
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    handleSuccess(response, file, fileList) {
      this.fileList.push(fileList[0])
    },
    xmchange(e) {
      this.forminfo.FS = this.childList.find((object) => object.id === e).fs
    },
  },
}
</script>
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.page-tool {
  padding: 20px;
  width: 100%;
  height: 70px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.page-tool .el-input {
  width: 300px;
}

.page-tool .el-button {
  margin-left: 10px;
}
.c_table {
  box-sizing: border-box;
  height: 100%;
  padding: 20px;
  overflow-y: auto;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #303030;
  font-weight: 700;
  padding: 5px 0;
}
.form-item-group {
  display: flex;
  color: #409eff;
  align-items: center;
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.form-item-group i {
  margin-right: 5px;
}
.DivTable {
  width: 99%;
  margin: 0 auto;
  margin-top: 10px;
}
table {
  display: table;
  border-collapse: collapse;
  box-sizing: border-box;
  text-indent: initial;
  white-space: normal;
  line-height: normal;
  font-weight: normal;
  font-size: medium;
  font-style: normal;
  color: -internal-quirk-inherit;
  text-align: start;
  border: 1px solid #bdc8d3;
  font-variant: normal;
}
.DivTable tr {
  min-height: 32px;
  border: 0;
}
.DivTable .EdtTitleBack {
  background-color: #fbfafa;
  text-align: right;
  height: 32px;
  overflow: hidden;
}
.DivTable td {
  min-height: 28px;
  border: 0;
  border: 1px solid #bdc8d3;
}
.DivTable .EdtBack {
  background-color: #fff;
  text-align: left;
  height: 32px;
  overflow: hidden;
}
.form-item-group {
  display: flex;
  color: #409eff;
  align-items: center;
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.form-item-group i {
  margin-right: 5px;
}
.staffBmTitle {
  margin-top: 1px;
  font-weight: 700;
  text-align: center;
  display: block;
  color: #000;
  font-size: 24px;
  margin-bottom: 10px;
}
.inputnoborder >>> .el-input__inner {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom-color: #000;
}
.staffBmTable {
  width: 100%;
  font-size: 14px;
  text-align: center;
  border-color: #000;
}
.staffBmTable tr td {
  padding: 10px;
}
.cell-class {
  border-color: #000 !important;
}
.rspan {
  width: 100px !important;
  text-align: right;
}
</style>
